<template>
  <LiefengContent>
    <template v-slot:title> 动员网络 </template>
    <template v-slot:toolsbarLeft>
      <LiefengTree
        ref="mychild"
        :fixedmenu="true"
        @handleData="getList"
        treeID="mychild"
        fixedContentID="contentarea"
        style="width: 200px"
      ></LiefengTree>
    </template>
    <template v-slot:toolsbarRight>
      <Input
      :maxlength="20"
        enter-button
        v-model.trim="searchData.groupName"
        placeholder="请输入动员群名"
        style="margin-right:10px;width: 150px"
      />
      <Input
      :maxlength="20"
        enter-button
        width="100px"
        v-model.trim="searchData.manager"
        placeholder="请输入负责人"
        style="margin-right:10px;width:150px"
      />
       <Button type="primary" icon="ios-search" @click="getList">搜索</Button>
      <Button type="success" icon="ios-refresh" @click="rest">重置</Button>
      <Button icon="ios-mail" type="warning" @click="addSendGroup" v-if="orgCode">发群公告</Button>
      <Button
        @click="exportExcel"
        type="primary"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        height="200"
        :loading="loading"
        :fixTable="true"
        :curPage="curPage"
        :total="total"
        :page-size="pageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
        @hadlePageSize="hadlePageSize"
      ></LiefengTable>
      <!-- 发送群公告 -->
      <LiefengModal
        title="发送群公告"
        width="80%"
        height="calc(100vh - 200px)"
        :value="sendGroupStatus"
        @input="sendGroupStatusFn"
        class="group"
      >
        <template v-slot:contentarea>
           <Collapse v-model="callapseValue" style="margin-top: 20px"> 
        <Panel name="1">
            <span class="validate">选择接收群范围</span>
            <p slot="content">
              <span style="margin-bottom: 10px">
                <Button type="primary" @click="allSelectGroup" style="margin: 0 10px">全选</Button>
                <Button type="primary" @click="deleteSelectGroup" style="margin: 0 10px">清除选择</Button>
                <Button type="primary" @click="expandSelectGroup" style="margin: 0 10px">全展开</Button>
                <Button type="primary" @click="shrinkSelectGroup" style="margin: 0 10px">全收缩</Button>
              </span>
              <Tree :data="groupTreeData" empty-text="暂无群范围" @on-check-change="oneSelectGroupTree" show-checkbox multiple></Tree>
            </p>
        </Panel>
        </Collapse>
        <Form>
          <FormItem>
            <span class="validate" slot="label">公告内容:</span>
            <Input v-model="content" type="textarea" :rows="4" :maxlength="400" show-word-limit placeholder="请填写内容，限400字" />
          </FormItem>
        </Form>

        <!-- 发送失败弹窗 -->
        <Modal v-model="sendError" footer-hide :closable="false" :width="400">
          <h2 style="color: red;text-align: center">发送失败！</h2>
          <div style="text-align: center;margin: 15px 0 5px"> <Button style="margin-right: 16px" @click="sendError = false;cancelModalTip = false;sendGroupStatusFn(false)">不发了</Button>
          <Button type="primary" @click="sendGroup">重新发送</Button></div>
      </Modal>
        </template>
        <template v-slot:toolsbar>
            <Button type="info" style="margin-right: 10px" @click="cancelModalTip = true;sendGroupStatusFn(false)">取消</Button>
            <Button type="primary" @click="sendGroup">发送</Button>
        </template>
      </LiefengModal>
      <!-- 外部负责人弹窗 -->
      <LiefengModal
        title="外部负责人"
        width="800px"
        :value="outsideModal"
        @input="outsideModalFn"
      >
        <template v-slot:contentarea>
          <Header style="background: white; padding: 0px">
            勾选社区下的管理员，选中则成为管理员，管理员可以对内部管理员和户代表进行管理，对聊天内容进行管理</Header
          >
          <Content v-if="manageList">
            <Button type="primary" @click="allSelectManager">全选</Button>
            <Form
              class="selecForm"
              :model="selecForm"
              label-position="left"
              style="
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
              "
            >
              <FormItem
                style="width: 30%"
                v-for="(item, index) in manageList"
                :key="index"
              >
                <Checkbox
                  v-model="item.status"
                  @on-change="checkboxChange(item)"
                  >{{ item.manage }}</Checkbox
                >
              </FormItem>
            </Form>
            <Page
              :pageSize="userpageSize"
              :pageNum="usercurPage"
              :total="usertotal"
              @on-change="getUserPage"
            ></Page>
          </Content>
        </template>
      </LiefengModal>
      <!-- 发言管理 -->
      <LiefengModal
        title="消息详情"
        :fullscreen="true"
        :value="msgManageModal"
        @input="msgManageModalFn"
      >
        <template v-slot:contentarea>
          <MsgManage
            :msgManageData="msgManageData"
            @replyMessageSuccess="hadlePageSize"
            v-if="msgManageModal"
          ></MsgManage>
        </template>
      </LiefengModal>
      <!-- 群二维码 -->
      <LiefengModal
        title="群二维码推广"
        :value="officialStatus"
        @input="officialStatusFn"
        :fullscreen="true"
      >
        <template v-slot:contentarea>
          <h3>该链接可以用于配置到公众号进行推广</h3>
          <div
            style="
              display: flex;
              justify-content: space-between;
              line-height: 32px;
              margin: 14px auto;
            "
          >
            <span>公众号链接：</span>
            <Input
              v-model="officialUrl"
              search
              enter-button="复制"
              style="flex: 1"
              @on-search="copyShareCode"
            ></Input>
          </div>
          <!-- <div
            style="
              display: flex;
              justify-content: space-between;
              line-height: 32px;
              margin: 14px auto;
            "
          >
            <span style="width:84px">短信链接：</span>
            <Input
              v-model="noteUrl"
              search
              enter-button="复制"
              style="flex: 1;margin-right: 100px"
              @on-search="copyNoteUrl"
            ></Input>
          </div> -->
          <h3>
            推广二维码已生成，可下载后直接分享二维码到微信（朋友、朋友圈）
          </h3>
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <div class="qrcode" ref="haibaoImg">
              <div class="h1h3">
                <h1>网上家园楼栋码</h1>
                <h3>[{{ qrOCdeName }}]</h3>
              </div>
              <!-- qrOCdeName -->
              <p>微信扫码完善信息进入楼栋群</p>
              <span>{{ qrCodeOrgName }}</span>
              <img
                :src="codeImgUrl + '?' + new Date().getTime()"
                alt=""
                ref="qrCode"
                crossOrigin="anonymous"
              />
            </div>
            <div style="width: 130px; height: 100%; margin-left: 30px">
              <Button
                type="primary"
                @click="downloadImgCode"
                style="margin-bottom: 20px; width: 130px"
                >下载纯二维码</Button
              >
              <Button type="warning" @click="mergeImage">下载宣传二维码</Button>
            </div>
          </div>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="officialStatusFn(false)">关闭</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/mobilizeindex")
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import LiefengTree from "@/components/LiefengTree";
import { format } from "@/utils/formatTime.js";
import MsgManage from "@/views/mobilize/childrens/msgmanage";
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      searchData: {},
      loading: false,
      //groupId
      groupId: "",
      total: 0,
      status: 2,
      manageList: [],
      rootData: {},
      treeData: [],
      tableData: [],
      talbeColumns: [
        {
          title: "动员群",
          key: "groupName",
          maxWidth: 150,
          align: "center",
        },
        {
          title: "家庭户代表数",
          width: 130,
          key: "contacts",
          align: "center",
        },
        {
          title: "创建时间",
          key: "gmtCreate",
          align: "center",
          width: 170,
        },
        {
          title: "负责人",
          align: "center",
          minWidth: 80,
          render: (h, params) => {
            var text = "";
            var data = params.row.chatGroupManager;
            for (var i = 0; i < data.length; i++) {
              if (data[i].manager != null) {
                if (text != "") text += "、";
                text += data[i].manager;
              }
            }
            return h("div", [{ text: text }]);
          },
        },
        {
          title: "发言数",
          key: "messageCount",
          width: 100,
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          width: 100,
          align: "center",

          render: (h, params) => {
            return h("div", [
              h(
                "Dropdown",
                {
                  props: {
                    transfer: true,
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        type: "info",
                        size: "small",
                        icon: "ios-arrow-down",
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "DropdownMenu",
                    {
                      slot: "list",
                    },
                    [
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.goMsg(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "发言管理"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.userMsg(params.row);
                              this.selectManagerByGroupId(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "外部负责人"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.goContact(params);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "户代表管理"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.qrOCdeName = params.row.groupName;
                              this.openOfficial(params.row.regionCode);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "群二维码"
                      ),
                    ]
                  ),
                ]
              ),
            ]);
          },
        },
      ],
      selection: [],
      showDo: true,
      hidenBread: true,
      hideSearch: false,
      curPage: 1,
      pageSize: 20,
      outsideModal: false,
      selecForm: {
        manager: "",
        managerId: "",
        groupId: "",
        oemCode: "",
        operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        single: false,
      },
      userpageSize: 20,
      usercurPage: 1,
      usertotal: 0,
      treeParams: {
        orgCode: "", //必填
        regionId: "",
        // orgId: "",
        pageSize: 20,
        page: 1,
        oemCode: "", //必填  缓存拿
      },
      //菜单树社区名称和编码
      orgCode: "",
      orgName: "",
      //发言管理
      msgManageData: null,
      msgManageModal: false,

      // 公众号链接
      officialStatus: false,
      officialUrl: "",
      codeImgUrl: "",
      qrCodeList: {}, //二维码参数
      qrCodeOrgName: parent.vue.loginInfo.userinfo.orgName,
      qrOCdeName: "",

      communityProjectCode: '',
      roadCode: '',


      // 发送群公告
      sendGroupStatus: false,
      cancelModalTip: false,
      callapseValue: ['1'],  //展开收缩群范围
      groupTreeData: [],  //群范围
      groupIds: [],
      content: '',
      sendError: false,
      noteUrl:'',
      noteCode:'',
    };
  },
  methods: {
    // 发送群公告
    sendGroup() {
      if(this.groupIds.length == 0) {
        this.$Message.error({
          background: true,
          content: "请选择接收的群范围"
        })
        return;
      }
      if(!this.content) {
        this.$Message.error({
          background: true,
          content: "请填写公告内容"
        })
        return;
      }
      this.$post('/sendmsg/pc/chat/group/addBatchGroupAffiche',{
        businessCode: '2',
        businessType: '2',
        content: this.content,
        groupIds: this.groupIds.join(','),
        oemCode: parent.vue.oemInfo.oemCode,
        creator: parent.vue.loginInfo.userinfo.realName,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
      }).then(res => {
        if(res.code == 200) {
          this.$Message.success({
            background: true,
            content: "发送成功！"
          })
          this.cancelModalTip = false;
          this.sendGroupStatus = false;
        }else {
          this.sendError = true;
        }
      })
    },
    addSendGroup() {
      this.$Message.loading({
        content: "正在获取数据，请稍等...",
        duration: 0
      })
      this.$get('/sendmsg/pc/chat/group/getChatRegionTree',{
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: this.orgCode
      }).then(res => {
        this.$Message.destroy();
        if(res.code == 200 && res.dataList.length > 0) {
          this.groupTreeData = res.dataList;
          this.sendGroupStatus = true;
        }else if(res.code == 200 && res.dataList.length == 0) {
          this.$Message.error({
            background: true,
            content: "暂未查询到您的群范围"
          })
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      }).catch(err => {
        this.$Message.destroy();
        console.log(err);
        this.$Message.error({
          background: true,
          content: "发送群公告功能异常，请联系管理员处理"
        })
      })
    },
    sendGroupStatusFn(status) {
       if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.sendGroupStatus = status;
            this.content = '';
            this.groupIds = []
          },
        });
      }else if(!status && !this.cancelModalTip) {
        this.sendGroupStatus = status;
            this.content = '';
            this.groupIds = []
      } else {
        this.sendGroupStatus = status;
      }
    },
      // 单次选中群范围
  oneSelectGroupTree(arr) {
    this.groupIds = [];
    this.selectGroupTree(arr);
  },
    // 全选，单次选中时，push到regionIds
    selectGroupTree(arr) {
      arr.map(item => {
        if(!item.children || item.children.length == 0) {
          this.groupIds.push(item.groupId);
          this.groupIds = Array.from(new Set(this.groupIds));
        }else {
          this.selectGroupTree(item.children);
        }
      });
    },
    // 封装全选和反选群范围
    allDeleteSelectGroup(tree,checkVal,expandVal) {
      const result = [];
    tree.forEach((item) => {
      let id = item.id;
      let groupId = item.groupId;
      let parentId = item.parentId;
      let expand = expandVal !== '' ? expandVal : item.expand;
      let checked = checkVal !== '' ? checkVal : item.checked;
      let title = item.title;
      let code = item.code;
      let children = item.children;
      // 如果有子节点，递归
      if (children && children.length != 0) {
        children = this.allDeleteSelectGroup(children, checkVal,expandVal);
      }
      result.push({ expand, code, checked, title, children,id,parentId,groupId });
    })
    return result;
    },
    // 全选群范围
    allSelectGroup() {
     this.groupIds = [];
     this.groupTreeData = this.allDeleteSelectGroup(this.groupTreeData,true,'');
     this.selectGroupTree(this.groupTreeData);
    },
    // 反选群范围
    deleteSelectGroup() {
      this.groupTreeData = this.allDeleteSelectGroup(this.groupTreeData,false,'');
      this.groupIds = [];
    },
    expandSelectGroup() {
      this.groupTreeData = this.allDeleteSelectGroup(this.groupTreeData,"",true);
    },
    shrinkSelectGroup() {
      this.groupTreeData = this.allDeleteSelectGroup(this.groupTreeData,"",false);
    },
    // 群二维码
    officialStatusFn(status) {
      this.officialStatus = status;
    },
    // 合并生产海报并下载
    mergeImage() {
      html2canvas(this.$refs.haibaoImg, { useCORS: true }).then(function (
        canvas
      ) {
        let src = canvas.toDataURL();
        var image = new Image();

        // 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function () {
          canvas.width = image.width;
          canvas.height = image.height;
          var context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);
          var url = canvas.toDataURL("image/png", 1.0);
          var a = document.createElement("a");
          var event = new MouseEvent("click");
          a.download = "群推广宣传二维码";
          a.href = url;
          a.dispatchEvent(event);
        };
        image.src = src;
      });
    },
    // 反向获取上级tree数据，到街道
   async getParentCode(code) {
      // 反向获取上级tree数据，到街道
     await this.$get("/gx/pc/region/selectRegionRoad", {
        orgCode: code,
      }).then((res) => {
        if (res.code == 200 && res.data) {
          this.qrCodeList = res.data;
        }
      });
    },
   async openOfficial(code) {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0,
      });
      if(code) {
        await this.getParentCode(code);
      }else {
        await this.getParentCode(this.roadCode);
      }
      let type = "";
      let params = {};
      if (this.qrCodeList.buildCode) {
        type = "ONLINE_HOME_BUILDING";
        params = {
          // recommendId: parent.vue.loginInfo.userinfo.id,
          projectCode: this.communityProjectCode, //社区
          blockInfo: {
            //街巷
            blockCode: this.qrCodeList.roadCode,
            blockName: this.qrCodeList.roadName,
            blockId: this.qrCodeList.roadId,
          },
          buildingInfo: {
            //楼栋
            buildingCode: this.qrCodeList.buildCode,
            buildingName: this.qrCodeList.buildName,
            buildingId: this.qrCodeList.buildId,
          },
        };
      }else {
        type = "ONLINE_HOME_BLOCK";
        params = {
          // recommendId: parent.vue.loginInfo.userinfo.id,
          projectCode: this.communityProjectCode, //社区
          blockInfo: {
            //街巷
            blockCode: this.qrCodeList.roadCode,
            blockName: this.qrCodeList.roadName,
            blockId: this.qrCodeList.roadId,
          },
        };
      }
      this.$post(
        "/datamsg/api/pc/wxqr/createWxAppletQr",
        {
          generateType: "1",
          width: 200,
          type: type,
          value: JSON.stringify({
            path: "pages/welcome/welcome",
            redirectPath: "/chatPages/pages/chat-group/index",
            params: params,
          }),
          appletCode: "suiyue",
        },
        { "Content-Type": "application/json" }
      )
        .then((res) => {
          this.$Message.destroy();
          if (res.code == 200) {
            this.officialUrl = "pages/welcome/welcome?scene=" + res.data.code;
            this.codeImgUrl = res.data.codeUrl;
            this.officialStatus = true;
            this.noteCode = res.data.code
            // this.$post(
            //   "/verifyapi/api/sytop/pc/urlLink/createUrlLink",
            //   {
            //     path:'pages/welcome/welcome',
            //     query: `scene=${this.noteCode}`,
            //   },
            // ).then(res => {
            //   if (res.code == 200 && res.data) {
            //     this.noteUrl = res.data.link
            //   }
            // });
          } else {
            this.$Message.error({
              background: true,
              content: "公众号链接生成失败！请联系管理员解决",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.destroy();
          this.$Message.error({
            background: true,
            content: "获取群二维码失败，请联系管理员处理",
          });
        });
    },
    downloadImgCode() {
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "群推广二维码";
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = this.codeImgUrl;
    },
    copyShareCode() {
      var oInput = document.createElement("input");
      oInput.value = this.officialUrl;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$Message.success({
        background: true,
        content: "复制链接成功！",
      });
    },
    // 点击复制短信链接
    copyNoteUrl() {
      var oInput = document.createElement("input");
      oInput.value = this.noteUrl;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$Message.success({
        background: true,
        content: "复制链接成功！"
      })
    },
    //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "动员群",
            "家庭户代表数",
            "创建时间",
            "负责人",
            "发言数",
          ];
          let filterVal = [
            "groupName",
            "contacts",
            "gmtCreate",
            "chatGroupManager",
            "messageCount",
          ];
          let tableData = JSON.parse(JSON.stringify(this.tableData));
          tableData.map((item, index) => {
            let text = "";
            var data = item.chatGroupManager;
            for (var i = 0; i < data.length; i++) {
              if (data[i].manager != null) {
                if (text != "") text += "、";
                text += data[i].manager;
              }
            }
            item.chatGroupManager = text;
          });
          this.$core.exportExcel(tHeader, filterVal, tableData, "动员体系列表");
        },
      });
    },
    // 重置
    rest() {
      this.searchData = {};
      this.getList()
    },
    //菜单树
    getList(data) {
      if (data) {
        if (data.communityCode) {
          this.orgCode = data.communityCode;
          //只有选了街道下才加载表格数据
          if (!data.projectCode && !data.roadCode) return;
          if (data.roadCode) {
            this.roadCode = data.roadCode;   //先取街道编码，查看群推广二维码用
          }
          this.loading = true;
          this.orgName = data.orgName;
          this.communityProjectCode = data.communityProjectCode;
          this.tableData = [];
          this.treeParams = {
            ...data,
            status: 2, //必填
            orgCode: this.orgCode, //社区
            oemCode: this.rootData.oemCode,
            page: data.page,
            pageSize: data.pageSize,
            regionId: data.regionId, //楼栋
            roadId: data.orgId, //街巷ID
          };
          this.curPage = data.page;
          this.pageSize = data.pageSize;
        }
        this.loading = true;
        if (data.page) {
          //切换页面时
          this.treeParams.page = data.page;
          this.treeParams.pageSize = data.pageSize;
        }
        if (data.cancelable) {
          //点击查询时  查询返回第一页的数据
          this.treeParams.page = 1;
          this.curPage = 1;
        }
      }
      this.$post(
        "/sendmsg/pc/chat/group/selectByChatGroupPage",
        {
          ...this.treeParams,
          groupName: this.searchData.groupName,
          manager: this.searchData.manager
        }
      )
        .then((res) => {
          if (res.code === "200") {
            //过滤时间
            res.dataList.map((item, index, arr) => {
              arr[index].gmtCreate = format(
                item.gmtCreate,
                "yyyy-MM-dd HH:mm:ss"
              );
            });
            this.spinShow = false;
            this.tableData = res.dataList;
            this.total = res.maxCount;
            this.curPage = res.currentPage;
            this.treeParams.name = "";
            this.treeParams.phone = "";
            this.loading = false;
          } else {
            this.loading = false;
            this.$Message.error({
              background: true,
              content: "数据获取失败",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //分页器
    hadlePageSize(obj) {
      this.getList(obj);
    },
    // 外部负责人弹窗状态变化
    outsideModalFn(status) {
      if (!status) {
        //关闭弹窗更新表格数据
        this.getList();
      }
      this.outsideModal = status;
    },
    // 发言管理弹窗状态变化
    msgManageModalFn(status) {
      this.msgManageModal = status;
    },
    //全选
    allSelectManager() {
      let manageStatusArr = [];
      this.manageList.map((item, index, arr) => {
        arr[index].status = true;
        manageStatusArr.push({
          manager: item.manage,
          managerId: item.manageId,
        });
      });
      this.$post(
        "/sendmsg/pc/chat/groupmanager/addManagerBatch",
        {
          chatManagerAddRoList: manageStatusArr,
          groupId: this.groupId,
          oemCode: parent.vue.loginInfo.userinfo.oemCode,
          operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        },
        { "Content-Type": "application/json" }
      ).then((res) => {
        if (res.code == 200) {
          this.$Message["success"]({
            background: true,
            content: "新增全部成功！",
          });
        } else {
          this.$Message["error"]({
            background: true,
            content: "新增失败！",
          });
        }
      });
    },
    //多选框勾选取消
    checkboxChange(data) {
      if (data.status) {
        //新增
        let params = {
          groupId: this.groupId,
          manager: data.manage,
          managerId: data.manageId,
          oemCode: parent.vue.loginInfo.userinfo.oemCode,
          operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        };
        this.$post("/sendmsg/pc/chat/groupmanager/addManager", params).then(
          (res) => {
            if (res.code == 200) {
              this.$Message["success"]({
                background: true,
                content: "新增成功！",
              });
            } else {
              this.$Message["error"]({
                background: true,
                content: "新增失败！",
              });
            }
          }
        );
      } else {
        //删除
        let params = {
          groupId: this.groupId,
          custGlobalId: data.manageId,
          operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        };
        this.$post("/sendmsg//pc/chat/groupmanager/delManager", params).then(
          (res) => {
            if (res.code == 200) {
              this.$Message["success"]({
                background: true,
                content: "删除成功！",
              });
            } else {
              this.$Message["error"]({
                background: true,
                content: "删除失败！",
              });
            }
          }
        );
      }
    },
    //分页
    getUserPage(data) {
      this.userPage({
        page: data,
        pageSize: this.userpageSize,
      });
    },
    //发言管理
    goMsg(data) {
      let str = "";
      data.chatGroupManager.map((item, index) => {
        if (index != data.chatGroupManager.length - 1) {
          str += item.manager + "、";
        } else {
          str += item.manager;
        }
      });
      this.msgManageData = data;
      this.msgManageData.manager = str;
      this.msgManageModal = true;
    },
    selectManagerByGroupId(data) {
      if (data.groupId) {
        this.$get("/sendmsg/pc/chat/groupmanager/selectManagerByGroupId", {
          groupId: data.groupId,
        })
          .then((res) => {
            if (res.code === "200") {
              this.managerIdList = res.dataList;
            } else {
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$Message.error("无groupId");
      }
    },
    //userPage分页发生改变
    userPage(obj) {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0,
      });
      let params = {
        groupId: this.groupId,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: this.rootData.orgCode,
        staffId: this.rootData.id,
        page: obj.page,
        pageSize: obj.pageSize,
      };
      this.$get("/sendmsg/pc/chat/groupmanager/selectManagerStaffPage", params)
        .then((res) => {
          this.$Message.destroy();
          if (res.code === "200") {
            this.manageList = res.dataList;
            this.usercurPage = res.currentPage;
            this.userpageSize = res.pageSize;
            this.usertotal = res.maxCount;
            this.outsideModal = true;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.$Message.error({
            backgroundtrue,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    userMsg(data) {
      if (data) {
        this.groupId = data.groupId;
      }
      this.userPage({
        page: 1,
        pageSize: 20,
      });
    },
    //联络人管理
    goContact(params) {
      let Base64 = require("js-base64").Base64;
      this.$router.push({
        path: "/mobilizecontacter",
        query: {
          data: Base64.encode(JSON.stringify(params.row)),
        },
      });
    },
  },
  created() {
    this.rootData = parent.vue.loginInfo.userinfo;
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    LiefengTree,
    MsgManage,
  },
};
</script>
    
<style scoped lang='less'>
.qrcode {
  position: relative;
  width: 400px;
  height: 565px;
  background: url("/images/pic_hd.jpg") no-repeat;
  background-size: 100% 100%;
  img {
    position: absolute;
    top: 256px;
    left: 83px;
    width: 230px;
  }
  .h1h3 {
    width: 100%;
    position: absolute;
    top: 75px;
    text-align: center;
  }
  h1 {
    text-align: center;
    font-size: 32px;
    color: #c62e23;
    margin-bottom: 10px;
  }
  h3 {
    padding: 0 30px;
    text-align: center;
    font-size: 26px;
    color: #c62e23;
  }
  p {
    width: 100%;
    position: absolute;
    top: 223px;
    text-align: center;
    font-size: 16px;
    color: #000;
  }
  span {
    width: 100%;
    position: absolute;
    text-align: center;
    top: 488px;
    font-size: 17px;
    color: #000;
  }
}
.group {
  /deep/.ivu-modal-close {
  display: none;
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
}
</style>